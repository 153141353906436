import React from'react';
import "./Components/fonts/font-awesome/css/font-awesome.min.css"
import "./Components/fonts/fonts.css";
import "./Components/css/main.css";
import useAnimation from './Helper/useAnimation';
import FragmentRenderer from './Helper/FragmentRenderer';
import generatedGitInfo from 'src/generatedGitInfo.json';


function IntroPage() {

    const htmlString = `
    <div class="preloader-background">
        <div class="preloader-wrapper big active">
            <div>
                <div class="hm-spinner"></div>
            </div>
        </div>
    </div>

    <div class="capp-main-wrapper">
        <!-- Header -->
        <header class="capp-brandbar">
            <div class="capp-brandbar--inner capp--boundbox">
                <a href="javascript: void(0);" class="app-logo">
                    <img src="${process.env.PUBLIC_URL}/static/images/capp-logo.png" alt="CALISTA"/>
                </a>

                <nav class="capp-nav--menu">
                    <a href="#partnerwithconfidence"
                    class="capp-nav--menu-item text-link jsIsSolutionsOpened jsJumpToSection">About us</a>
                        <div class="dropdown jsMoreMenuDrawer">
                        <a href="javascript: void(0);" class="capp-nav--menu-item text-link drop-menu">
                            Solutions
                            <i class="icon-arrow down"></i>
                        </a>

                    </div>
                    <div class="dropdown-content capp-megamenu">
                        <div class="capp-megamenu--inner">
                            <div class="row">
                                    <!-- Mega menu columns :: column 01 -->
                                <div class="col col-12 col-lg-4">
                                    <ul class="capp-megamenu--list-wrapper">
                                        <li class="capp-megamenu--list-item">
                                            <a href="https://calistalogistics.com/" target="_blank"
                                            class="capp-nav--menu-item text-link">CALISTA book and track</a>
                                            </li>
                                            <li class="capp-megamenu--list-item">
                                                <a href="https://www.calista-ecommerce.com/" target="_blank" class="capp-nav--menu-item text-link">CALISTA eCommerce</a>
                                        </li>
                                        <li class="capp-megamenu--list-item">
                                                <a href="https://www.calista-finance.com/" target="_blank" class="capp-nav--menu-item text-link">CALISTA finance</a>
                                        </li>
                                        <li class="capp-megamenu--list-item">
                                                <a href="https://www.calista-tech.com/" target="_blank" class="capp-nav--menu-item text-link">CALISTA for tech</a>
                                        </li>
                                        <li class="capp-megamenu--list-item">
                                                <a href="https://www.tradeadvisory.ai/" target="_blank" class="capp-nav--menu-item text-link">CALISTA intelligent advisory (CIA)</a>
                                        </li>
                                    </ul>
                                </div>

                                    <!-- Mega menu columns :: column 02 -->
                                    <div class="col col-12 col-lg-4">
                                    <ul class="capp-megamenu--list-wrapper">
                                        <li class="capp-megamenu--list-item">
                                                <a href="https://www.gets-bpo.com/" target="_blank" class="capp-nav--menu-item text-link">Business Process Outsourcing (BPO)</a>
                                            </li>
                                            <li class="capp-megamenu--list-item">
                                                <a href="https://www.gets-ior.com/" target="_blank" class="capp-nav--menu-item text-link">Importer of Records (IOR)</a>
                                        </li>
                                            <li class="capp-megamenu--list-item">
                                                <a href="https://forms.office.com/Pages/ResponsePage.aspx?id=GKbhxR65b0K280KdBhWFQRFZlAihXr1JlxEU_u2AFTlUQlpQNEJaWUQ1T0kxVFoxVkU0NjRXQTZUOSQlQCN0PWcu" target="_blank" class="capp-nav--menu-item text-link">PSA VAS & Free P!NG</a>
                                            </li>
                                            <li class="capp-megamenu--list-item">
                                                <a href="https://xborderusa.com/" target="_blank" class="capp-nav--menu-item text-link">XborderUSA</a>
                                            </li>
                                    </ul>
                                    </div>

                                    <!-- Mega menu columns :: column 03 -->
                                <div class="col col-12 col-lg-4">
                                    <ul class="capp-megamenu--list-wrapper">
                                        <li class="capp-megamenu--list-item">
                                                <a href="https://developer.globaletrade.services/" target="_blank" class="capp-nav--menu-item text-link">CALISTA API</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="capp-nav--segmentation jsIsSolutionsOpened">
                        <input type="hidden" id="env-tag" name="env-tag" value="Development"/>
                        <a href="${process.env.PUBLIC_URL}/login"
                            class="capp-nav--menu-item capp-btn btn-outline-text-link white js-calista-login" target="_blank">Log in
                        </a>
                        <!-- <a href="javascript: void(0);" class="capp-nav--menu-item capp-btn btn-text-link white">Sign up</a> -->
                    </div>

                    <div class="capp-nav--segmentation separate-me-left mkt-campaign jsIsSolutionsOpened">
                        <a href="javascript: void(0);" class="capp-nav--menu-item capp-btn btn-text-link orange contact-me">Enquire
                            now</a>
                    </div>
                </nav>

                <div class="capp-nav--segmentation lang">

                    <a data-bs-toggle="popover" data-bs-placement="bottom" data-bs-container="body" data-bs-html="true"
                    data-bs-placement="bottom" href="javascript:void(0);" id="ca-locale"
                    data-content-id="popover-content-ca-locale"
                    class="capp-btn btn-outline-text-link white lang-drop-btn">
                        <i class="fa fa-globe"></i>
                        <label class="lang-item">EN</label>
                        <i class="lang-item icon-arrow down"></i>
                    </a>

                    <div id="popover-content-ca-locale" class="d-none">
                        <ul class="lang-list-group">
                            <li class="lang-list--item active">
                                <a href="./home">
                                    English
                                </a>
                            </li>
                            <li class="lang-list--item">
                                <a href="./home-cn">
                                    中文
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <a href="javascript: void(0);" class="capp-nav-drawer-btn capp-btn btn-outline-text-link white jsIsSolutionsOpened">
                    <label>Menu</label>
                    <label>X</label>
                </a>

                <div class="capp-nav-drawer--overlay"></div>
            </div>
        </header>
        <!--End Header -->

        <main>
            <!-- Section: Jumbotron banner -->
            <section id="aboutcalista" class="capp-jumbotron" data-aos="fade-up">
                <div class="capp--boundbox w-100 h-100">
                    <div class="capp-banner-container">
                        <div class="container-fluid h-100 p-0">
                            <div class="row g-0 capp-banner--inner-flex">
                                <div class="col-lg-6 col-md-12 col-sm-12">
                                    <div class="capp-banner--description">
                                        <h2 class="title-secondary">End-to-End, Supply Chain Solutions Platform</h2>
                                        <ul class="pipe-list">
                                            <li>Compliance</li>
                                            <li>Logistics</li>
                                            <li>Financing</li>
                                            <li>Managed Services</li>
                                        </ul>

                                        <h1 class="title-primary mt-30">Move Cargo Seamlessly.</h1>
                                        <h1 class="title-primary wrap-two-lines">Across Borders,  Around The World.</h1>
                                        <p class="display-text mt-30">Our enterprise SaaS platform and deep expertise of
                                            over 30 years of trade compliance experience provides you with the one-stop
                                            supply chain solution for your business needs.</p>

                                        <span class="capp-button-group">
                                                <!-- <a href="javascript: void(0);" class="capp-btn btn-tertiary-text-link orange post-adornment">
                                                    <label>Find out more</label>
                                                    <i class="action-icon icon-arrow-forward"></i>
                                                    <svg class="action-icon" xmlns="http://www.w3.org/2000/svg" width="24"
                                                        height="24" viewBox="0 0 24 24">
                                                        <path id="Path_2156" data-name="Path 2156" d="M0,0H24V24H0Z"
                                                            fill="none"/>
                                                        <path id="Path_2157" data-name="Path 2157"
                                                            d="M12,4,10.59,5.41,16.17,11H4v2H16.17l-5.58,5.59L12,20l8-8Z"/>
                                                    </svg>
                                                </a> -->

                                                <a href="javascript: void(0);"
                                                class="capp-btn btn-text-link orange contact-me">Enquire now</a>
                                            </span>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-12 col-sm-12">
                                    <figure>
                                        <img src="${process.env.PUBLIC_URL}/static/images/banners/CALISTA_platform.png" alt="About CALISTA platform and its services"/>
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <!-- Section: Marketing updates -->
            <section id="marketingupdates" class="capp-carousel" data-aos="fade-up">
                <div class="capp--boundbox w-100 h-100">
                    <div id="carousel-marketingupdates" class="carousel slide carousel-fade w-100" data-bs-ride="carousel"
                        data-bs-touch="true" data-bs-interval="4000">

                        <!-- Note: Dev. team reference: un-comment the below carousel indicator block when more than one carousel is added to the screen -->
                            <!-- <div class="carousel-indicators">
                                <button type="button" data-bs-target="#carousel-marketingupdates" data-bs-slide-to="0" class="active" aria-current="true" aria-label="PSA VAS"></button>
                                <button type="button" data-bs-target="#carousel-marketingupdates" data-bs-slide-to="1" aria-label="Freight eXchange"></button>
                            </div> -->

                        <div class="carousel-inner h-100">
                            <div class="carousel-item h-100 active">
                                <img src="${process.env.PUBLIC_URL}/static/images/banners/psa-vas-jumbo.jpg" class="mkt-fig d-block w-100" alt="PSA Value Added Services">

                                <div class="carousel-caption">
                                    <h5 class="title-primary">PSA Value Added Services &amp; Free P!NG</h5>
                                    <p class="display-text mt-10">Request for PSA's Value-Added Services (VAS) and access to
                                        key event milestones for containers via Singapore such as Estimated/Actual Arrival
                                        and Departure Time, Container Loaded/Discharged Time and Container Gate In/Out
                                        Time.</p>

                                    <span class="capp-button-group">
                                            <a href="javascript: void(0);" class="capp-btn btn-text-link orange js-request-psa-vas-ping">Request now</a>
                                        </span>
                                </div>
                            </div>

                                <!-- Note: Dev. team reference: Additional carousel item  -->
                                <!-- <div class="carousel-item h-100">
                                <img src="./src/assets/images/banners/freight-xchange.jpg" class="d-block w-100" alt="Freght Exchange">

                                <div class="carousel-caption">
                                    <h5 class="title-primary">CALISTA Freight eXchange</h5>
                                    <p class="display-text mt-10">A Marketplace for the Future of Logistics Management.</p>

                                    <span class="capp-button-group">
                                        <a href="javascript: void(0);" class="capp-btn btn-text-link orange">Sign up now</a>
                                    </span>
                                </div>
                            </div> -->

                        </div>
                    </div>
                </div>
            </section>

            <!-- Section: Supply chain solutions -->
            <section id="oursupplychainsolutions" class="capp-supply-chain-solutions" data-aos="fade-up"
                    data-aos-delay="500">
                <div class="capp--boundbox w-100 h-100">
                    <div class="capp-section-container">
                        <div class="capp-supply-chain--description" data-aos="fade-up" data-aos-delay="550">
                            <h2 class="title-secondary">One end-to-end supply chain solution. For all your needs.</h2>
                            <p class="display-text mt-24">Leverage on our core expertise in Trade Compliance to navigate
                                cross-border customs import/export processes with ease. Opt for world-class logistics,
                                financing and managed services to seamlessly integrate your supply chain processes!</p>
                        </div>

                        <!-- Trade Compliance Services -->
                        <article class="capp-tradecomp-services">
                            <div class="capp-tradecomp-services--description" data-aos="fade-up" data-aos-delay="600">
                                <h3 class="title-tertiary">Trade Compliance</h2>
                                    <ul class="bullet-list">
                                        <li>One-stop portal to file customs and security declarations</li>
                                        <li>Customs advisory, compliance & declaration solutions</li>
                                    </ul>
                            </div>

                            <div class="capp-tradecomp--boxes-container main-services">
                                <div class="capp-tradecomp--box white-box" data-aos="flip-up" data-aos-delay="750">
                                    <h2 class="title-secondary">Pre-clearance</h2>
                                    <h1 class="title-primary">CALISTA intelligent advisory (CIA)</h1>
                                    <p class="display-text">Minimise tax costs and navigate trade processes in 180+
                                        countries</p>

                                    <ul class="bullet-list">
                                        <li>Harmonised System (HS) / Tariff codes</li>
                                        <li>Preferential duties and taxes</li>
                                        <li>Trade formalities and documentation</li>
                                    </ul>

                                    <span class="capp-button-group mt-30 justify-center">
                                            <a href="https://www.tradeadvisory.ai/CIA/"
                                            class="capp-btn medium btn-outline-text-link gray"
                                            target="_blank">Learn more</a>
                                        </span>
                                </div>

                                <div class="capp-tradecomp--box white-box" data-aos="flip-up" data-aos-delay="850">
                                    <h2 class="title-secondary">Clearance</h2>
                                    <h1 class="title-primary">CALISTA regulatory filing</h1>
                                    <p class="display-text">Fulfil regulatory requirements through 60+ Customs nodes</p>

                                    <ul class="bullet-list">
                                        <li>Intelligent Data Extraction</li>
                                        <li>Data Transformation</li>
                                        <li>Data Messaging</li>
                                        <li>Connectivity to > 60 Customs Nodes</li>
                                    </ul>
                                </div>

                                <div class="capp-tradecomp--box white-box" data-aos="flip-up" data-aos-delay="950">
                                    <h2 class="title-secondary">Post-clearance</h2>
                                    <h1 class="title-primary">CALISTA post clearance</h1>
                                    <p class="display-text">Always ready for post-clearance audit and review</p>

                                    <ul class="bullet-list">
                                        <li>Readiness Assessment</li>
                                        <li>Record management, archival & retrieval</li>
                                        <li>Case / License Management</li>
                                        <li>Analytics</li>
                                        <li>Visibility and flexi-alerts</li>
                                    </ul>
                                </div>
                            </div>
                        </article>

                        <article class="capp-tradecomp-services--linked">
                            <div class="capp-tradecomp--boxes-container linked-services">
                                <div class="capp-tradecomp--box blue-box" data-aos="flip-up" data-aos-delay="800">
                                    <h2 class="title-secondary">Logistics</h2>
                                    <h1 class="title-primary">CALISTA book and track</h1>
                                    <p class="display-text">Logistics fulfilment capabilities & access to global network of
                                        LSPs</p>

                                    <ul class="bullet-list">
                                        <li>Access over 6000 global logistics service providers for ocean, air, rail, freight bookings and last mile.</li>
                                        <li>Get customized SLAs, transparent pricing and shipment visibility throughout the entire process.</li>

                                        <li>
                                            <label>Calculate carbon emission in your supply chain with our carbon footprint calculator. <a href="https://calistalogistics.com/carbon-footprint-calculator" target="_blank">Learn more<i class="action-icon icon-link-external-orange size-16"></i></a>
                                            </label>
                                        </li>
                                    </ul>

                                    <span class="capp-button-group mt-30 justify-center">
                                            <a href="https://calistalogistics.com/"
                                            class="capp-btn medium btn-outline-text-link gray"
                                            target="_blank">Learn more</a>
                                        </span>
                                </div>

                                <div class="capp-tradecomp--box blue-box" data-aos="flip-up" data-aos-delay="900">
                                    <h2 class="title-secondary">Financing</h2>
                                    <h1 class="title-primary">CALISTA finance</h1>
                                    <p class="display-text">Trade financing and digital payment options</p>

                                    <ul class="bullet-list">
                                        <li>Invoice financing</li>
                                        <li>Accounts receivable financing (factoring)</li>
                                        <li>Working capital credit lines</li>
                                        <li>Credit cards, bank-to-bank and B2B wallet payment options</li>
                                    </ul>

                                    <span class="capp-button-group mt-30 justify-center">
                                            <a href="https://www.calista-finance.com/"
                                            class="capp-btn medium btn-outline-text-link gray"
                                            target="_blank">Learn more</a>
                                        </span>
                                </div>

                                <div class="capp-tradecomp--box blue-box" data-aos="flip-up" data-aos-delay="1000">
                                    <h2 class="title-secondary">Managed services</h2>

                                    <span class="managed-service--clickable justify-center">
                                            <a href="https://www.gets-ior.com/" class="capp-btn btn-outline-text-link gray"
                                            target="_blank">
                                                <label>Importer of Records (IOR)</label>
                                            </a>
                                        </span>

                                    <span class="managed-service--clickable justify-center">
                                            <a href="https://www.gets-bpo.com/" class="capp-btn btn-outline-text-link gray"
                                            target="_blank">
                                            <label>Business Process Outsourcing (BPO)</label>
                                            <em>*For Singapore only</em>
                                            </a>
                                        </span>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </section>

            <!-- Our solutions -->
            <section id="oursolutions" class="capp-our-solutions alt-section" data-aos="fade-up" data-aos-delay="500">
                <div class="capp--boundbox w-100 h-100">
                    <div class="capp-section-container">
                        <div class="capp-alt-section--description" data-aos="fade-up" data-aos-delay="600">
                            <h2 class="title-primary">Use our solutions the way you like.</h2>
                            <p class="display-text">3 available options to use or setup CALISTA for your business. Speak to
                                our seasoned team of industry-leading technical experts to find out more.</p>
                        </div>

                        <article class="capp-our-solution--card-wrapper">
                            <div class="row">
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                                    <!-- Solution box -->
                                    <div class="capp-our-solution--box" data-aos="flip-up" data-aos-delay="700">
                                        <span class="capp-section-icon icon-plug-and-play"></span>
                                        <h3 class="title-tertiary">Plug & Play</h3>
                                        <p class="display-text">Simply sign up and access relevant services through the
                                            CALISTA platform.</p>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                                    <!-- Solution box -->
                                    <div class="capp-our-solution--box" data-aos="flip-up" data-aos-delay="800">
                                        <span class="capp-section-icon icon-api-integration"></span>
                                        <h3 class="title-tertiary">API integration</h3>
                                        <p class="display-text">Maintain your own User Interface and integrate with CALISTA
                                            via API.</p>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                                    <!-- Solution box -->
                                    <div class="capp-our-solution--box" data-aos="flip-up" data-aos-delay="900">
                                        <span class="capp-section-icon icon-white-labelling"></span>
                                        <h3 class="title-tertiary">White labelling</h3>
                                        <p class="display-text">Your company’s corporate colours and logo. Our user
                                            interface.</p>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
                <!-- An anchor position for next section -->
                <div id="anchorAtPartnerwithconfidence" style="height: 0;"></div>
            </section>

            <!-- Our partners trust -->
            <section id="partnerwithconfidence" class="capp-our-solutions alt-section" data-aos="fade-up"
                    data-aos-delay="500">
                <div class="capp--boundbox w-100 h-100">
                    <div class="capp-section-container">
                        <div class="capp-alt-section--description" data-aos="fade-up" data-aos-delay="750">
                            <h2 class="title-primary">Partner us with confidence.</h2>
                            <p class="display-text">GeTS is revolutionizing the way businesses conduct global trade through
                                innovative tech solutions like CALISTA and deep expertise in supply chains around the world,
                                across all channels.</p>
                        </div>

                        <article class="capp-our-solution--card-wrapper">
                            <div class="row">
                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                    <!-- Solution box -->
                                    <div class="capp-our-solution--box" data-aos="flip-up" data-aos-delay="850">
                                        <span class="capp-section-icon icon-globe-connected"></span>
                                        <h3 class="title-tertiary">Plugged In & Well-Connected</h3>
                                        <p class="display-text">Linkages to over 60 Customs nodes, 90 ocean carriers &
                                            NVOCCs, as well as 175 000 connected parties, globally.</p>
                                    </div>
                                </div>

                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                    <!-- Solution box -->
                                    <div class="capp-our-solution--box" data-aos="flip-up" data-aos-delay="950">
                                        <span class="capp-section-icon icon-home-for-expert"></span>
                                        <h3 class="title-tertiary">Deep B2B & G2B Expertise</h3>
                                        <p class="display-text">More than 24 million transactions annually with businesses
                                            and governments across 5 continents with over 30 years of experience.</p>
                                    </div>
                                </div>

                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" data-aos="flip-up" data-aos-delay="1050">
                                    <!-- Solution box -->
                                    <div class="capp-our-solution--box">
                                        <span class="capp-section-icon icon-trusted-accredited"></span>
                                        <h3 class="title-tertiary">Internationally Accredited & Trusted</h3>
                                        <p class="display-text">Winner of numerous supply chain management solutions awards
                                            and international certifications/accreditations.</p>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                    <!-- Solution box -->
                                    <div class="capp-our-solution--box" data-aos="flip-up" data-aos-delay="1150">
                                        <span class="capp-section-icon icon-partnership"></span>
                                        <h3 class="title-tertiary">Backed by Industry Heavyweights</h3>
                                        <p class="display-text">CALISTA was developed in partnership with PSA International,
                                            one of the world’s leading port groups in 19 countries.</p>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </section>
        </main>

        <!-- Start Footer -->
        <footer class="capp-footer" data-aos="fade-up">
            <section id="footerlinks" class="capp-footer--link-container">
                <div class="capp-footer--inner capp--boundbox">
                    <div class="row w-100">
                        <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                            <!-- Solution box -->
                            <div class="capp-footer--link-box sm-only">
                                <h3 class="title-tertiary">GeTS Asia Pte Ltd</h3>
                                <p class="display-text">31 Science Park Road</p>
                                <p class="display-text">The Crimson</p>
                                <p class="display-text">Singapore 117611</p>
                            </div>
                        </div>

                        <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                            <!-- Solution box -->
                            <div class="capp-footer--link-box sm-only">
                                <h3 class="title-tertiary">Browse</h3>
                                <ul class="bullet-list no-bullet">
                                    <li>
                                        <a href="https://globaletrade.services/gets-profile" target="_blank">About</a>
                                    </li>
                                    <li>
                                        <!-- <a href="mailto:support@globaletrade.services">Support</a> -->
                                        <a class="contact-me" href="javascript:void(0);" onclick='javascript:window.location.href="mailto:support@globaletrade.services"; return false;'>Support</a>
                                    </li>
                                    <li>
                                        <a class="contact-me" href="javascript: void(0)">Contact Us</a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                            <!-- Solution box -->
                            <div class="capp-footer--link-box">
                                <h3 class="title-tertiary">Social</h3>
                                <ul class="bullet-list no-bullet list-horizontal">
                                    <li data-aos="zoom-in-down" data-aos-delay="500">
                                        <a href="https://www.facebook.com/Global.eTrade.Services/" target="_blank" title="Follow us on Facebook">
                                            <i class="social-link-icon icon-facebook"></i>
                                        </a>
                                    </li>
                                    <li data-aos="zoom-in-down" data-aos-delay="650">
                                        <a href="https://twitter.com/clgets?lang=en" target="_blank" title="Follow us on Twitter">
                                            <i class="social-link-icon icon-twitter"></i>
                                        </a>
                                    </li>
                                    <li data-aos="zoom-in-down" data-aos-delay="750">
                                        <a href="https://www.linkedin.com/company/global-etrade-services-gets" target="_blank" title="Follow us on LinkedIn" >
                                            <i class="social-link-icon icon-linkedin"></i>
                                        </a>
                                    </li>
                                        <li data-aos="zoom-in-down" data-aos-delay="850">
                                        <a href="https://www.youtube.com/channel/UCbaPDpqi0fEyyg7S7orrv_Q" target="_blank" title="Watch us on YouTube" >
                                            <i class="social-link-icon icon-youtube"></i>
                                        </a>
                                    </li>
                                </ul>
                                <figure>
                                    <img src="${process.env.PUBLIC_URL}/static/images/we-chat-qrcode.png" alt="WeChat QR"/>
                                    <figcaption></figcaption>
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="copyright" class="capp-footer--copyright-container">
                <div class="capp-footer--inner capp--boundbox">
                    <div class="row w-100">
                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                            <!-- Solution box -->
                            <div class="capp-footer--link-box sm-only">
                                <p class="display-text">&copy; <span id="copyright-year"></span> CALISTA. All rights reserved.v${generatedGitInfo.gitCommitHash}</p>
                            </div>
                        </div>

                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                            <div class="capp-footer--link-box">
                                <ul class="bullet-list no-bullet list-horizontal">
                                    <li>
                                        <a href="https://globaletrade.services/privacy-policy" target="_blank"
                                        title="Privacy Policy">
                                            Privacy Policy
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://globaletrade.services/terms-use" target="_blank"
                                        title="Terms of Service">
                                            Terms of Service
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </footer>
        <!-- End Footer -->
    </div>


    <!-- Go to top link button -->
    <a href="javascript:voi(0);" id="go-to-top" class="top-of-site-link" data-visible="false">
        <span class="visually-hidden-focusable">Back to Top</span>
        <svg width="32" height="32" viewBox="0 0 100 100">
            <path fill="#000"
                d="m50 0c-13.262 0-25.98 5.2695-35.355 14.645s-14.645 22.094-14.645 35.355 5.2695 25.98 14.645 35.355 22.094 14.645 35.355 14.645 25.98-5.2695 35.355-14.645 14.645-22.094 14.645-35.355-5.2695-25.98-14.645-35.355-22.094-14.645-35.355-14.645zm20.832 62.5-20.832-22.457-20.625 22.457c-1.207 0.74219-2.7656 0.57812-3.7891-0.39844-1.0273-0.98047-1.2695-2.5273-0.58594-3.7695l22.918-25c0.60156-0.61328 1.4297-0.96094 2.2891-0.96094 0.86328 0 1.6914 0.34766 2.293 0.96094l22.918 25c0.88672 1.2891 0.6875 3.0352-0.47266 4.0898-1.1562 1.0508-2.9141 1.0859-4.1133 0.078125z"></path>
        </svg>
    </a>

    <script src="${process.env.PUBLIC_URL}/js-min/jquery.min.js"></script>
    <script src="${process.env.PUBLIC_URL}/js-min/bootstrap.min.js"></script>
    <script src="${process.env.PUBLIC_URL}/js-min/main.js"></script>
    `;
    
    const def = useAnimation();
  
    return React.createElement(FragmentRenderer, { text: htmlString })
}

export default IntroPage
