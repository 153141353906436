import React from'react';
import "./Components/fonts/font-awesome/css/font-awesome.min.css"
import "./Components/fonts/fonts.css";
import "./Components/css/main.css";
import useAnimation from './Helper/useAnimation';
import FragmentRenderer from './Helper/FragmentRenderer';


function IntroPageCN() {

    const htmlString = `
        <div class="preloader-background">
            <div class="preloader-wrapper big active">
                <div>
                    <div class="hm-spinner"></div>
                </div>
            </div>
        </div>

        <div class="capp-main-wrapper">
            <!-- Header -->
            <header class="capp-brandbar">
                <div class="capp-brandbar--inner capp--boundbox">
                    <a href="javascript: void(0);" class="app-logo">
                        <img src="${process.env.PUBLIC_URL}/static/images/capp-logo.png" alt="CALISTA"/>
                    </a>

                    <nav class="capp-nav--menu">
                        <a href="#partnerwithconfidence" class="capp-nav--menu-item text-link jsIsSolutionsOpened jsJumpToSection">关于我们</a>
                            <div class="dropdown jsMoreMenuDrawer">
                            <a href="javascript: void(0);" class="capp-nav--menu-item text-link drop-menu">
                                解决方案
                                <i class="icon-arrow down"></i>
                            </a>
                        </div>

                        <div class="dropdown-content capp-megamenu">
                            <div class="capp-megamenu--inner">
                                <div class="row">
                                        <!-- Mega menu columns :: column 01 -->
                                    <div class="col col-12 col-lg-4">
                                        <ul class="capp-megamenu--list-wrapper">
                                            <li class="capp-megamenu--list-item">
                                                <a href="https://calistalogistics.com/" target="_blank" class="capp-nav--menu-item text-link">CALISTA 下单与跟踪</a>
                                            </li>
                                            <li class="capp-megamenu--list-item">
                                                <a href="https://www.calista-ecommerce.com/" target="_blank" class="capp-nav--menu-item text-link">CALISTA 电商</a>
                                            </li>
                                            <li class="capp-megamenu--list-item">
                                                <a href="https://www.calista-finance.com/" target="_blank" class="capp-nav--menu-item text-link">CALISTA 金融</a>
                                            </li>
                                            <li class="capp-megamenu--list-item">
                                                <a href="https://www.calista-tech.com/" target="_blank" class="capp-nav--menu-item text-link">CALISTA 技术</a>
                                            </li>
                                                <li class="capp-megamenu--list-item">
                                                    <a href="https://www.tradeadvisory.ai/" target="_blank" class="capp-nav--menu-item text-link">CALISTA 智能贸易咨询 (CIA)</a>
                                                </li>
                                        </ul>
                                    </div>

                                        <!-- Mega menu columns :: column 02 -->
                                    <div class="col col-12 col-lg-4">
                                        <ul class="capp-megamenu--list-wrapper">
                                            <li class="capp-megamenu--list-item">
                                                <a href="https://www.gets-bpo.com/" target="_blank" class="capp-nav--menu-item text-link">业务流程外包 (BPO)</a>
                                            </li>
                                            <li class="capp-megamenu--list-item">
                                                <a href="https://www.gets-ior.com/" target="_blank" class="capp-nav--menu-item text-link">进口商备案服务 (IOR)</a>
                                            </li>
                                            <li class="capp-megamenu--list-item">
                                                    <a href="https://forms.office.com/Pages/ResponsePage.aspx?id=GKbhxR65b0K280KdBhWFQRFZlAihXr1JlxEU_u2AFTlUQlpQNEJaWUQ1T0kxVFoxVkU0NjRXQTZUOSQlQCN0PWcu" target="_blank" class="capp-nav--menu-item text-link">PSA 增值服务与免费 PING</a>
                                            </li>
                                            <li class="capp-megamenu--list-item">
                                                <a href="https://xborderusa.com/" target="_blank" class="capp-nav--menu-item text-link">XborderUSA</a>
                                            </li>
                                        </ul>
                                    </div>

                                        <!-- Mega menu columns :: column 03 -->
                                        <div class="col col-12 col-lg-4">
                                            <ul class="capp-megamenu--list-wrapper">
                                                <li class="capp-megamenu--list-item">
                                                    <a href="https://developer.globaletrade.services/" target="_blank" class="capp-nav--menu-item text-link">CALISTA API</a>
                                                </li>
                                            </ul>
                                        </div>
                                </div>
                            </div>
                        </div>

                        <div class="capp-nav--segmentation jsIsSolutionsOpened">
                            <input type="hidden" id="env-tag" name="env-tag" value="Development"/>
                            <a href="./login" class="capp-nav--menu-item capp-btn btn-outline-text-link white js-calista-login-cn" target="_blank">登录</a>
                            <!-- <a href="javascript: void(0);" class="capp-nav--menu-item capp-btn btn-text-link white">Sign up</a> -->
                        </div>

                        <div class="capp-nav--segmentation separate-me-left mkt-campaign jsIsSolutionsOpened">
                            <a href="javascript: void(0);" class="capp-nav--menu-item capp-btn btn-text-link orange contact-me">立即咨询</a>
                        </div>
                    </nav>

                    <div class="capp-nav--segmentation lang">

                        <a data-bs-toggle="popover" data-bs-placement="bottom" data-bs-container="body" data-bs-html="true"
                        data-bs-placement="bottom" href="javascript:void(0);" id="ca-locale"
                        data-content-id="popover-content-ca-locale"
                        class="capp-btn btn-outline-text-link white lang-drop-btn">
                            <i class="fa fa-globe"></i>
                            <label class="lang-item">CN</label>
                            <i class="lang-item icon-arrow down"></i>
                        </a>

                        <div id="popover-content-ca-locale" class="d-none">
                            <ul class="lang-list-group">
                                <li class="lang-list--item">
                                    <a href="./home">
                                        English
                                    </a>
                                </li>
                                <li class="lang-list--item active">
                                    <a href="./home-cn">
                                        中文
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <a href="javascript: void(0);" class="capp-nav-drawer-btn capp-btn btn-outline-text-link white jsIsSolutionsOpened">
                        <label>Menu</label>
                        <label>X</label>
                    </a>

                    <div class="capp-nav-drawer--overlay"></div>
                </div>
            </header>
            <!--End Header -->

            <!--  Start Main content -->
            <main>
                <!-- Section: Jumbotron banner -->
                <section id="aboutcalista" class="capp-jumbotron" data-aos="fade-up">
                    <div class="capp--boundbox w-100 h-100">
                        <div class="capp-banner-container">
                            <div class="container-fluid h-100 p-0">
                                <div class="row g-0 capp-banner--inner-flex">
                                    <div class="col-lg-6 col-md-12 col-sm-12">
                                        <div class="capp-banner--description">
                                            <h2 class="title-secondary">端到端的供应链解决方案平台</h2>
                                            <ul class="pipe-list">
                                                <li>贸易合规</li>
                                                <li>物流</li>
                                                <li>融资</li>
                                                <li>托管服务</li>
                                            </ul>

                                            <h1 class="title-primary mt-30">让全球跨境货运无缝运行</h1>
                                            <!-- <h1 class="title-primary wrap-two-lines">跨越国界，环游世界。 </h1> -->
                                            <p class="display-text mt-30">我们通过企业 SaaS 平台和 30 余年的贸易合规经验，提供一站式供应链解决方案以满足您的业务需求。</p>

                                            <span class="capp-button-group">
                                                    <!-- <a href="javascript: void(0);" class="capp-btn btn-tertiary-text-link orange post-adornment">
                                                        <label>了解更多</label>
                                                        <i class="action-icon icon-arrow-forward"></i>
                                                        <svg class="action-icon" xmlns="http://www.w3.org/2000/svg" width="24"
                                                            height="24" viewBox="0 0 24 24">
                                                            <path id="Path_2156" data-name="Path 2156" d="M0,0H24V24H0Z"
                                                                fill="none"/>
                                                            <path id="Path_2157" data-name="Path 2157"
                                                                d="M12,4,10.59,5.41,16.17,11H4v2H16.17l-5.58,5.59L12,20l8-8Z"/>
                                                        </svg>
                                                    </a> -->

                                                    <a href="javascript: void(0);" class="capp-btn btn-text-link orange contact-me">立即咨询</a>
                                                </span>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-12 col-sm-12">
                                        <figure>
                                            <img src="${process.env.PUBLIC_URL}/static/images/banners/CALISTA_platform.png" alt="About CALISTA platform and its services"/>
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                    <!-- Section: Marketing updates -->
                    <section id="marketingupdates" class="capp-carousel" data-aos="fade-up">
                        <div class="capp--boundbox w-100 h-100">
                            <div id="carousel-marketingupdates" class="carousel slide carousel-fade w-100" data-bs-ride="carousel" data-bs-touch="true" data-bs-interval="4000">

                                <!-- Note: Dev. team reference: un-comment the below carousel indicator block when more than one carousel is added to the screen -->
                                <!-- <div class="carousel-indicators"> -->
                                    <!-- <button type="button" data-bs-target="#carousel-marketingupdates" data-bs-slide-to="0" class="active" aria-current="true" aria-label="PSA VAS"></button> -->
                                    <!-- <button type="button" data-bs-target="#carousel-marketingupdates" data-bs-slide-to="1" aria-label="Freight eXchange"></button> -->
                                <!-- </div> -->
                                <div class="carousel-inner h-100">
                                    <div class="carousel-item h-100 active">
                                        <img src="${process.env.PUBLIC_URL}/static/images/banners/psa-vas-jumbo.jpg" class="mkt-fig d-block w-100" alt="PSA Value Added Services">

                                        <div class="carousel-caption">
                                            <h5 class="title-primary">PSA 增值服务与免费 PING</h5>
                                            <p class="display-text mt-10">申请 PSA 增值服务 (VAS)，并查看经新加坡的集装箱的关键进程，例如预计/实际到达和离开时间、集装箱装载/卸货时间、集装箱入港/出港时间。</p>

                                            <span class="capp-button-group">
                                                <a href="javascript: void(0);" class="capp-btn btn-text-link orange js-request-psa-vas-ping">立即申请</a>
                                            </span>
                                        </div>
                                    </div>

                                    <!-- Note: Dev. team reference: Additional carousel item
                                        <div class="carousel-item h-100">
                                        <img src="./src/assets/images/banners/freight-xchange.jpg" class="d-block w-100" alt="Freght Exchange">

                                        <div class="carousel-caption">
                                            <h5 class="title-primary">CALISTA Freight eXchange</h5>
                                            <p class="display-text mt-10">A Marketplace for the Future of Logistics Management.</p>

                                            <span class="capp-button-group">
                                                <a href="javascript: void(0);" class="capp-btn btn-text-link orange">Sign up now</a>
                                            </span>
                                        </div>
                                    </div> -->

                                </div>
                            </div>
                        </div>
                    </section>

                <!-- Section: Supply chain solutions -->
                <section id="oursupplychainsolutions" class="capp-supply-chain-solutions" data-aos="fade-up"
                        data-aos-delay="500">
                    <div class="capp--boundbox w-100 h-100">
                        <div class="capp-section-container">
                            <div class="capp-supply-chain--description" data-aos="fade-up" data-aos-delay="550">
                                <h2 class="title-secondary">可满足您供应链解决方案所有端到端的需求</h2>
                                <p class="display-text mt-24">您可以利用我们核心的贸易合规专业经验，轻松驾驭跨境海关进出口流程。选择世界一流的物流、融资和托管服务，无缝集成您的供应链流程！</p>
                            </div>

                            <!-- Trade Compliance Services -->
                            <article class="capp-tradecomp-services">
                                <div class="capp-tradecomp-services--description" data-aos="fade-up" data-aos-delay="600">
                                    <h3 class="title-tertiary">贸易合规</h2>
                                        <ul class="bullet-list">
                                            <li>海关备案和安全申报的一站式门户</li>
                                            <li>海关咨询、合规及申报解决方案</li>
                                        </ul>
                                </div>

                                <div class="capp-tradecomp--boxes-container main-services">
                                    <div class="capp-tradecomp--box white-box" data-aos="flip-up" data-aos-delay="750">
                                        <h2 class="title-secondary">预先清关</h2>
                                        <h1 class="title-primary">CALISTA 智能贸易咨询 (CIA)</h1>
                                        <p class="display-text">最大限度地降低税收成本，在180 多个国家/地区提供贸易流程支持</p>

                                        <ul class="bullet-list">
                                            <li>协调制度 (HS)编码 / 关税编码</li>
                                            <li>优惠关税和税收</li>
                                            <li>贸易手续和单证</li>
                                        </ul>

                                            <span class="capp-button-group mt-30 justify-center">
                                                <a href="https://www.tradeadvisory.ai/CIA/" class="capp-btn medium btn-outline-text-link gray" target="_blank">了解更多</a>
                                            </span>
                                    </div>

                                    <div class="capp-tradecomp--box white-box" data-aos="flip-up" data-aos-delay="850">
                                        <h2 class="title-secondary">通关</h2>
                                        <h1 class="title-primary">CALISTA 监管备案</h1>
                                        <p class="display-text">通过 60 多个海关节点满足监管要求</p>

                                        <ul class="bullet-list">
                                            <li>智能数据提取</li>
                                            <li>数据转换</li>
                                            <li>数据传递</li>
                                            <li>连接至超过60个海关节点</li>
                                        </ul>
                                    </div>

                                    <div class="capp-tradecomp--box white-box" data-aos="flip-up" data-aos-delay="950">
                                        <h2 class="title-secondary">通关后</h2>
                                        <h1 class="title-primary">CALISTA 通关后</h1>
                                        <p class="display-text">随时支持通关后审核及检查</p>

                                        <ul class="bullet-list">
                                            <li>审核准备情况评估</li>
                                            <li>记录管理、存档和检索</li>
                                            <li>案件 / 许可证管理</li>
                                            <li>分析</li>
                                            <li>可见性和灵活警告</li>
                                        </ul>
                                    </div>
                                </div>
                            </article>

                            <article class="capp-tradecomp-services--linked">
                                <div class="capp-tradecomp--boxes-container linked-services">
                                    <div class="capp-tradecomp--box blue-box" data-aos="flip-up" data-aos-delay="800">
                                        <h2 class="title-secondary">物流</h2>
                                        <h1 class="title-primary">CALISTA下单与跟踪</h1>
                                        <p class="display-text">物流履行能力和接入全球 LSP 网络</p>

                                        <ul class="bullet-list">
                                            <li>与 6000 多家全球物流服务供应商合作，提供海运、空运、铁路货运预订和最后一英里配送服务。</li>
                                            <li>全程可获得定制的 SLA、透明的定价和货运可视性。</li>

                                            <li>
                                                <label>使用我们的计算器计算您供应链中的碳排放量。<a href="https://calistalogistics.com/carbon-footprint-calculator" target="_blank">了解更多。<i class="action-icon icon-link-external-orange size-16"></i></a>
                                                </label>
                                            </li>
                                        </ul>

                                            <span class="capp-button-group mt-30 justify-center">
                                                <a href="https://calistalogistics.com/" class="capp-btn medium btn-outline-text-link gray" target="_blank">了解更多</a>
                                            </span>
                                    </div>

                                    <div class="capp-tradecomp--box blue-box" data-aos="flip-up" data-aos-delay="900">
                                        <h2 class="title-secondary">融资</h2>
                                        <h1 class="title-primary">CALISTA 金融</h1>
                                        <p class="display-text">贸易融资与数字支付选项</p>

                                        <ul class="bullet-list">
                                            <li>发票融资</li>
                                            <li>应收账款融资（保理）</li>
                                            <li>营运资金信贷额度</li>
                                            <li>信用卡、银行对银行和 B2B 钱包支付选项</li>
                                        </ul>

                                            <span class="capp-button-group mt-30 justify-center">
                                                <a href="https://www.calista-finance.com/" class="capp-btn medium btn-outline-text-link gray" target="_blank">了解更多</a>
                                            </span>
                                    </div>

                                    <div class="capp-tradecomp--box blue-box" data-aos="flip-up" data-aos-delay="1000">
                                        <h2 class="title-secondary">托管服务</h2>

                                        <span class="managed-service--clickable justify-center">
                                                <a href="https://www.gets-ior.com/" class="capp-btn btn-outline-text-link gray" target="_blank">
                                                    <label>进口商备案服务 (IOR)</label>
                                                </a>
                                            </span>
                                        <span class="managed-service--clickable justify-center">
                                                <a href="https://www.gets-bpo.com/" class="capp-btn btn-outline-text-link gray" target="_blank">
                                                <label>业务流程外包 (BPO)</label>
                                                <em>* 仅适用于新加坡</em>
                                                </a>
                                            </span>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                </section>

                <!-- Our solutions -->
                <section id="oursolutions" class="capp-our-solutions alt-section" data-aos="fade-up" data-aos-delay="500">
                    <div class="capp--boundbox w-100 h-100">
                        <div class="capp-section-container">
                            <div class="capp-alt-section--description" data-aos="fade-up" data-aos-delay="600">
                                <h2 class="title-primary">您可以根据自己的需求使用我们的解决方案。</h2>
                                <p class="display-text">您可以选用三种方案，或者针对您的业务设置 CALISTA。请与我们的资深行业技术专家团队交流以了解更多信息。</p>
                            </div>

                            <article class="capp-our-solution--card-wrapper">
                                <div class="row">
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                                        <!-- Solution box -->
                                        <div class="capp-our-solution--box" data-aos="flip-up" data-aos-delay="700">
                                            <span class="capp-section-icon icon-plug-and-play"></span>
                                            <h3 class="title-tertiary">即插即用</h3>
                                            <p class="display-text">您只需在我们的 CALISTA 平台注册，即可使用相关服务。</p>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                                        <!-- Solution box -->
                                        <div class="capp-our-solution--box" data-aos="flip-up" data-aos-delay="800">
                                            <span class="capp-section-icon icon-api-integration"></span>
                                            <h3 class="title-tertiary">API 集成</h3>
                                            <p class="display-text">保留您自己的用户界面，并通过 API 与 CALISTA 集成。</p>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                                        <!-- Solution box -->
                                        <div class="capp-our-solution--box" data-aos="flip-up" data-aos-delay="900">
                                            <span class="capp-section-icon icon-white-labelling"></span>
                                            <h3 class="title-tertiary">白标</h3>
                                            <p class="display-text">您公司的企业色调和商标。我们的用户界面。</p>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                    <!-- An anchor position for next section -->
                    <div id="anchorAtPartnerwithconfidence" style="height: 0;"></div>
                </section>

                <!-- Our partners trust -->
                <section id="partnerwithconfidence" class="capp-our-solutions alt-section" data-aos="fade-up"
                        data-aos-delay="500">
                    <div class="capp--boundbox w-100 h-100">
                        <div class="capp-section-container">
                            <div class="capp-alt-section--description" data-aos="fade-up" data-aos-delay="750">
                                <h2 class="title-primary">您可以放心地与我们合作。</h2>
                                <p class="display-text">GeTS 通过 CALISTA 等创新技术解决方案以及石油渠道的全球供应链的深厚专业知识，正在彻底改变企业开展全球贸易的方式。</p>
                            </div>

                            <article class="capp-our-solution--card-wrapper">
                                <div class="row">
                                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                        <!-- Solution box -->
                                        <div class="capp-our-solution--box" data-aos="flip-up" data-aos-delay="850">
                                            <span class="capp-section-icon icon-globe-connected"></span>
                                            <h3 class="title-tertiary">良好的业务网络</h3>
                                            <p class="display-text">连接逾 60 个海关节点，同 90 个海运公司和无船承运人以及 17.5 万个相关方建立联系。</p>
                                        </div>
                                    </div>

                                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                        <!-- Solution box -->
                                        <div class="capp-our-solution--box" data-aos="flip-up" data-aos-delay="950">
                                            <span class="capp-section-icon icon-home-for-expert"></span>
                                            <h3 class="title-tertiary">深厚的 B2B 与 G2B 专业知识</h3>
                                            <p class="display-text">我们拥有 30 多年的专业经验，每年与五大洲的企业和政府达成逾 2400 万笔交易。 </p>
                                        </div>
                                    </div>

                                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" data-aos="flip-up" data-aos-delay="1050">
                                        <!-- Solution box -->
                                        <div class="capp-our-solution--box">
                                            <span class="capp-section-icon icon-trusted-accredited"></span>
                                            <h3 class="title-tertiary">国际认可和信誉</h3>
                                            <p class="display-text">荣获多项供应链管理解决方案大奖和国际认证/认可。</p>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                        <!-- Solution box -->
                                        <div class="capp-our-solution--box" data-aos="flip-up" data-aos-delay="1150">
                                            <span class="capp-section-icon icon-partnership"></span>
                                            <h3 class="title-tertiary">获得行业巨头支持</h3>
                                            <p class="display-text">CALISTA 是与新加坡国际港务集团合作开发的解决方案，该集团是世界领先的港口集团之一，业务遍及 19 个国家/地区。</p>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                </section>
            </main>
            <!--  End Main content -->

            <!-- Footer -->
            <footer class="capp-footer" data-aos="fade-up">
                <section id="footerlinks" class="capp-footer--link-container">
                    <div class="capp-footer--inner capp--boundbox">
                        <div class="row w-100">
                            <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                <!-- Solution box -->
                                <div class="capp-footer--link-box sm-only">
                                    <h3 class="title-tertiary">GeTS Asia Pte Ltd</h3>
                                    <p class="display-text">31 Science Park Road</p>
                                    <p class="display-text">The Crimson</p>
                                    <p class="display-text">Singapore 117611</p>
                                </div>
                            </div>

                            <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                <!-- Solution box -->
                                <div class="capp-footer--link-box sm-only">
                                    <h3 class="title-tertiary">浏览</h3>
                                    <ul class="bullet-list no-bullet">
                                        <li>
                                            <a href="https://globaletrade.services/gets-profile" target="_blank">关于我们</a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0);" onclick='javascript:window.location.href="mailto:support@globaletrade.services"; return false;' class="contact-me">支持和帮助</a>
                                        </li>
                                        <li>
                                            <a class="contact-me" href="javascript: void(0)">联系我们</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                <!-- Solution box -->
                                <div class="capp-footer--link-box">
                                    <h3 class="title-tertiary">社交媒体</h3>
                                    <ul class="bullet-list no-bullet list-horizontal">
                                        <li data-aos="zoom-in-down" data-aos-delay="500">
                                            <a href="https://www.facebook.com/Global.eTrade.Services/" target="_blank" title="Follow us on Facebook">
                                                <i class="social-link-icon icon-facebook"></i>
                                            </a>
                                        </li>
                                        <li data-aos="zoom-in-down" data-aos-delay="650">
                                            <a href="https://twitter.com/clgets?lang=zh" target="_blank" title="Follow us on Twitter">
                                                <i class="social-link-icon icon-twitter"></i>
                                            </a>
                                        </li>
                                        <li data-aos="zoom-in-down" data-aos-delay="750">
                                            <a href="https://www.linkedin.com/company/global-etrade-services-gets" target="_blank" title="Follow us on LinkedIn">
                                                <i class="social-link-icon icon-linkedin"></i>
                                            </a>
                                        </li>
                                            <li data-aos="zoom-in-down" data-aos-delay="850">
                                            <a href="https://www.youtube.com/channel/UCbaPDpqi0fEyyg7S7orrv_Q" target="_blank" title="Watch us on YouTube">
                                                <i class="social-link-icon icon-youtube"></i>
                                            </a>
                                        </li>
                                    </ul>
                                    <figure>
                                        <img src="${process.env.PUBLIC_URL}/static/images/we-chat-qrcode.png" alt="WeChat QR" />
                                        <figcaption></figcaption>
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="copyright" class="capp-footer--copyright-container">
                    <div class="capp-footer--inner capp--boundbox">
                        <div class="row w-100">
                            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <!-- Solution box -->
                                <div class="capp-footer--link-box sm-only">
                                    <p class="display-text">&copy; <span id="copyright-year"></span> CALISTA。保留所有权利。</p>
                                </div>
                            </div>

                            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <div class="capp-footer--link-box">
                                    <ul class="bullet-list no-bullet list-horizontal">
                                        <li>
                                            <a href="https://globaletrade.services/privacy-policy" target="_blank"
                                            title="Privacy Policy">
                                                隐私政策
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://globaletrade.services/terms-use" target="_blank"
                                            title="Terms of Service">
                                                服务条款
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </footer>
            <!-- Start Footer -->
        </div>

        <!-- Go to top link button -->
        <a href="javascript:voi(0);" id="go-to-top" class="top-of-site-link" data-visible="false">
            <span class="visually-hidden-focusable">Back to Top</span>
            <svg width="32" height="32" viewBox="0 0 100 100">
                <path fill="#000"
                    d="m50 0c-13.262 0-25.98 5.2695-35.355 14.645s-14.645 22.094-14.645 35.355 5.2695 25.98 14.645 35.355 22.094 14.645 35.355 14.645 25.98-5.2695 35.355-14.645 14.645-22.094 14.645-35.355-5.2695-25.98-14.645-35.355-22.094-14.645-35.355-14.645zm20.832 62.5-20.832-22.457-20.625 22.457c-1.207 0.74219-2.7656 0.57812-3.7891-0.39844-1.0273-0.98047-1.2695-2.5273-0.58594-3.7695l22.918-25c0.60156-0.61328 1.4297-0.96094 2.2891-0.96094 0.86328 0 1.6914 0.34766 2.293 0.96094l22.918 25c0.88672 1.2891 0.6875 3.0352-0.47266 4.0898-1.1562 1.0508-2.9141 1.0859-4.1133 0.078125z"></path>
            </svg>
        </a>

        
        <script src="/js-min/jquery.min.js"></script>
        <script src="/js-min/bootstrap.min.js"></script>
        <script src="/js-min/main.js"></script>
    `;

    const def = useAnimation(); 

    return React.createElement(FragmentRenderer, { text: htmlString });
}

export default IntroPageCN;

